import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const form = useRef()

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
    .sendForm(
      'service_exec9y8',
      'template_6flh185',
      form.current,
      'aanYBbuOQrzO4He9R'
    )
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
  }

  return (
    <>
    <div className="container contact-page">
    <div className="text-zone">
      <h1>
        <AnimatedLetters
          letterClass={letterClass}
          strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
          idx={15}
        />
      </h1>
      <p>
        I am very interested in web development and machine learning opportunities. However, if you have any requests or questions,
        please contact me using the form below.
      </p>
      <div className="contact-form">
        <form ref={form} onSubmit={sendEmail}>
          <ul>
            <li className="half">
              <input placeholder="Name" type="text" name="name" required />
            </li>
            <li className="half">
              <input
                placeholder="Email"
                type="email"
                name="email"
                required
              />
            </li>
            <li>
              <input
                placeholder="Subject"
                type="text"
                name="subject"
                required
              />
            </li>
            <li>
              <textarea
                placeholder="Message"
                name="message"
                required
              ></textarea>
            </li>
            <li>
              <input type="submit" className="flat-button" value="SEND" />
            </li>
          </ul>
        </form>
      </div>
    </div>
    <div className="info-map">
          Christian Franco,
          <br />
          Floral Park, NY, USA <br />
          <span>christianfranco1015@gmail.com</span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[40.723900, -73.705948]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[40.723900, -73.705948]}>
              <Popup>Let's grab a cup of coffee! </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}
export default Contact